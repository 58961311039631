.Spoiler {
  &--concealed {
    cursor: pointer;
    background-image: url('../../../assets/spoiler-dots-black.png');
    background-size: auto min(100%, 1.125rem);
    border-radius: 0.5rem;

    html.theme-dark &,
    html.theme-light .ListItem.selected &,
    .ActionMessage &,
    .MediaViewerFooter & {
      background-image: url('../../../assets/spoiler-dots-white.png');
    }

    .emoji-only & {
      background-size: auto 1.125rem;
    }
  }

  &--animated {
    animation: pulse-opacity-light 1.75s linear infinite;
  }

  &__content {
    opacity: 1;
    transition: opacity 250ms ease;
  }

  &--concealed &__content {
    user-select: none;
    opacity: 0;
  }
}

@keyframes pulse-opacity-light {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
}
