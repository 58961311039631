.root {
  display: inline-flex;
  vertical-align: text-bottom;
  width: var(--custom-emoji-size);
  height: var(--custom-emoji-size);
  position: relative;

  &.with-grid-fix .media, &.with-grid-fix .thumb {
    width: calc(100% + 1px) !important;
    height: calc(100% + 1px) !important;
  }

  &:global(.custom-color) {
    --emoji-status-color: var(--color-primary);
  }
}

.thumb {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.media {
  width: var(--custom-emoji-size) !important;
  height: var(--custom-emoji-size) !important;

  user-select: none !important;
}

.root, .media, .thumb {
  border-radius: 0 !important;
}

.highlightCatch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: auto !important;
}

.altEmoji {
  opacity: 0;
}
