// @optimization
@mixin while-transition() {
  .Transition > div:not(.Transition__slide--active) & {
    @content;
  }
}

@mixin overflow-y-overlay() {
  @supports (overflow-y: overlay) {
    overflow-y: overlay;

    //Workaround for Android <= 9
    overflow-x: hidden;
  }
}

@mixin reset-range() {
  input[type="range"] {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    height: 0.75rem;
    margin-bottom: 0.5rem;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-moz-slider-thumb {
      -moz-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      cursor: pointer;
    }

    &::-moz-range-track, &::-moz-range-progress {
      cursor: pointer;
    }
  }
}
