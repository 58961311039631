.CountryCodeInput {
  .input-group {
    cursor: pointer;

    z-index: var(--z-country-code-input-group);

    .Spinner {
      position: absolute;
      top: 1rem;
      right: 0.75rem;
      opacity: 0.5;
      --spinner-size: 1.5rem;
    }

    // Prevent loading additional 10 kB of icomoon font on initial load.
    .css-icon-down {
      position: absolute;
      top: 1.125rem;
      right: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      border: 2px solid var(--color-text-secondary);
      border-top: 0;
      border-left: 0;
      transform: scaleY(1) rotate(45deg);
      /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
      transition: color 0.2s ease, transform 0.2s ease, top 0.2s ease;

      &.open {
        border-color: var(--color-primary);
        transform: scaleY(-1) rotate(45deg);
        top: 1.5rem;
      }
    }
  }

  .bubble {
    width: 100%;
    max-height: 23.5rem;
    overflow-y: auto;
    transform-origin: top center !important;
    transform: scale(0.95);

    &.open {
      transform: scaleY(1);
    }
  }

  .MenuItem {
    button {
      display: flex;
      align-items: center;
    }

    &.no-results button {
      justify-content: center;
      padding: 0.5rem 1rem;

      span {
        font-size: 0.875rem;
      }
    }
  }

  .country-flag {
    font-size: 2rem;
    margin-right: 1rem;

    .emoji {
      width: 2rem;
      height: 2rem;
      display: inline-block;
      vertical-align: 4px;
      margin: -0.5rem 0.125rem;
    }
  }

  .country-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    text-align: left;
  }

  .country-code {
    margin-left: auto;
    opacity: 0.5;
    padding-right: 0.25rem;
  }
}
